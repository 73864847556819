function hoverMainMenu() {
    const menuImgs = document.querySelectorAll('.mainMenu__img');
    const menuItems = document.querySelectorAll('.mainMenu__item');
    const sublists = document.querySelectorAll('.mainMenu__sublist-wrapper');
    const sublistActive = 'mainMenu__sublist-wrapper_active';

    menuImgs.forEach((menuImg, i) => {
        menuImg.addEventListener('mouseenter', (e) => {
            sublists[i].classList.add(sublistActive);
        });
    });

    menuItems.forEach((menuItem, i) => {
        menuItem.addEventListener('mouseleave', (e) => {
            sublists[i].classList.remove(sublistActive);
        });
    });

}

export default hoverMainMenu;