function toggleHamburger({
    hamburgerMenuSelector,
    hamburgerMenuActiveSelector,
    hamburgerSelector,
    hamburgerActiveSelector
    }) {
    const hamburgerMenu = document.querySelector(hamburgerMenuSelector);
    const hamburger = document.querySelector(hamburgerSelector);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerActiveSelector);
        hamburgerMenu.classList.toggle(hamburgerMenuActiveSelector);

        if (document.body.style.overflow === "") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    });
}

export default toggleHamburger;