function initSlider({
    slide, 
    prevArrow, 
    nextArrow, 
    wrapper,
    field,
    automatically
    }) {
    const slides = document.querySelectorAll(slide);
    const sliderArrowPrev = document.querySelector(prevArrow);
    const sliderArrowNext = document.querySelector(nextArrow);
    const slidesWrapper = document.querySelector(wrapper);
    const slidesField = document.querySelector(field);
    const slidesWrapperWidth = window.getComputedStyle(slidesWrapper).width;
    let slideIndex = 1;
    let offset = 0; // переменная, которая показывает количество отступа
    
    slidesField.style.width = 100 * slides.length + '%';
    
    // если присваивать без setTimeout, то будет небольшой баг
    setTimeout(() => {
        slidesField.style.transition = '0.75s all';
    }, 100);
    
    slides.forEach(slide => {
        slide.style.width = slidesWrapperWidth;
    });

    // удаление "px" в строке и превращение строки в число
    function deleteNotDigits (str) {
        return +str.replace(/px/g, '');
    }

    setTimeout(() => {
        slides[1].style.backgroundImage = `url('../img/backgrounds/bg-2.jpg')`;
    }, 2000);

    function initLazyLoading() {
        if (slideIndex + 1 !== slides.length && slideIndex !== slides.length) {
            slides[slideIndex + 1].style.backgroundImage = `url('../img/backgrounds/bg-${slideIndex + 2}.jpg')`;
        }
    }

    // if(sliderArrowNext) {
    //     sliderArrowNext.addEventListener('click', () => {
    //         if (offset == deleteNotDigits(slidesWrapperWidth) * (slides.length - 1)) {
    //             offset = 0;
    //         } else {
    //             offset += deleteNotDigits(slidesWrapperWidth);
    //         }
    
    //         slidesField.style.transform = `translateX(-${offset}px)`;
    
    //         if (slideIndex == slides.length) {
    //             slideIndex = 1;
    //         } else {
    //             slideIndex++;
    //         }
    //     });
    // }

    // if(sliderArrowPrev) {
    //     sliderArrowPrev.addEventListener('click', () => {
    //         if (offset == 0) {
    //             offset = deleteNotDigits(slidesWrapperWidth) * (slides.length - 1);
    //         } else {
    //             offset -= deleteNotDigits(slidesWrapperWidth);
    //         }
    
    //         slidesField.style.transform = `translateX(-${offset}px)`;
    
    //         if (slideIndex == 1) {
    //             slideIndex = slides.length;
    //         } else {
    //             slideIndex--;
    //         }
    //     });
    // }

    if (automatically) {
        setInterval(() => {
            if (offset == deleteNotDigits(slidesWrapperWidth) * (slides.length - 1)) {
                offset = 0;
            } else {
                offset += deleteNotDigits(slidesWrapperWidth);
            }

            initLazyLoading();

            slidesField.style.transform = `translateX(-${offset}px)`;
    
            if (slideIndex == slides.length) {
                slideIndex = 1;
            } else {
                slideIndex++;
            }
        }, 5000);
    }
}

export default initSlider;