function colorActiveLanguageBtns(languagesSelector, headerLanguageSelector, headerActiveLanguageSelector, footerActiveLanguageSelector) {
    // languages - все кнопки, которые меняют язык
    const languages = document.querySelectorAll(languagesSelector);
    const headerActiveLanguage = headerActiveLanguageSelector;
    const footerActiveLanguage = footerActiveLanguageSelector;

    // функция сравнивает текст каждой кнопки с языком в localStorage
    function colorBtns(item) {
        // если он совпадает
        if (localStorage.getItem('language') === item.textContent) {
            // и если кнопка в хедере
            if (item.classList.contains(headerLanguageSelector)) {
                // то красим её вот так.
                item.classList.add(headerActiveLanguage);
            // если кнопка не в хедере, значит она в футере
            } else { 
                // и красим её вот так
                item.classList.add(footerActiveLanguage);
            }
        }
    }

    // если мы первый раз зашли на страницу  (localStorage пустой)
    if (!localStorage.getItem('language')) {
        // то язык будет русский
        localStorage.setItem('language', 'RU');

        // берем все кнопки
        languages.forEach(item => {
            // и красим нужные
            colorBtns(item);
        });
    }

    // если мы уже второй и более раз на странице, то берем все кнопки
    languages.forEach(item => {
        // и красим нужные.
        colorBtns(item);

        // дополнительно вешаем на них клик. после клика
        item.addEventListener('click', () => {
            // записываем текст кнопки в localStorage.
            localStorage.setItem('language', item.textContent);

            // потом снова берем все кнопки
            languages.forEach(item => {
                // снимаем классы активности со всех 
                item.classList.remove(headerActiveLanguage, footerActiveLanguage);

                // и задаем только нужным
                colorBtns(item);
            });
        });
    });
}

export default colorActiveLanguageBtns; 