'use strict';
import initVh from './modules/initVh';
import hideSpinner from './modules/hideSpinner';
import toggleHamburger from './modules/toggleHamburger';
import colorActiveLanguageBtns from './modules/colorActiveLanguageBtns';
import initSlider from './modules/slider';
import hoverMainMenu from './modules/hoverMainMenu';

window.addEventListener('DOMContentLoaded', () => {
    initVh();
    
    hideSpinner('.spinner-wrapper', '.page', 'block'); 

    toggleHamburger({
        hamburgerMenuSelector: '.header__list', 
        hamburgerMenuActiveSelector: 'header__list_active', 
        hamburgerSelector: '.header__hamburger', 
        hamburgerActiveSelector: 'header__hamburger_active'
    });

    colorActiveLanguageBtns(
        '[data-google-lang]', 
        'header__languages-btn', 
        'header__languages-btn_active', 
        'footer__languages-btn_active'
    );

    initSlider({
        slide: '.slider__wrapper_main .slider__item',
        wrapper: '.slider__wrapper_main',
        field: '.slider__wrapper_main .slider',
        automatically: true
    });

    hoverMainMenu();
});

